<template>
  <div>
    <b-card header="Search">
      <b-form-row>
        <b-col
          md="6"
          class="mb-1 mb-md-0"
        >
          <b-form-group
            label="Name"
            label-for="search"
          >
            <search
              placeholder="Name"
              :search-query.sync="searchQuery.keyword"
              @refresh="refreshTable"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Duration"
            label-for="date-range-filter"
          >
            <date-range-filter
              :date-range.sync="searchQuery.range"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Status"
            label-for="boolean-status-filter"
          >
            <boolean-status-filter :status.sync="searchQuery.status" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <event-type-select
            :event-type.sync="searchQuery.categoryId"
          />
        </b-col>

        <b-col
          md="2"
          class="mt-1 mt-md-0"
        >
          <search-button @refresh="refreshTable()" />
        </b-col>
      </b-form-row>
    </b-card>

    <b-card>
      <b-table
        ref="table"
        :items="getEvents"
        :fields="fields"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        responsive
        show-empty
      >
        <template #cell(actions)="{item}">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{name:'view-reclaimed-event',params:{id:item.id} }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{name:'quick-event-add-single-session',query:{event:convertCommunityToQuickEvent(item)} }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Convert to quick event</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>

    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />
  </div>
</template>
<script>
import paginationData from '@/common/compositions/common/paginationData'
import communityEventApi from '@/common/compositions/CommunityEvents/communityEventApi'
import Search from '@/common/components/common/Table/Search.vue'
import DateRangeFilter from '@/common/components/common/Table/DateRangeFilter.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import BooleanStatusFilter from '@/common/components/common/FormInputs/BooleanStatusFilter.vue'
import EventTypeSelect from '@/common/components/QuickEvents/EventTypeSelect.vue'

export default {
  name: 'CommunityEventsList',
  components: {
    Search,
    DateRangeFilter,
    Pagination,
    SearchButton,
    BooleanStatusFilter,
    EventTypeSelect,
  },
  data() {
    return {
      searchQuery: {
        range: [],
        status: null,
      },
    }
  },
  computed: {
    fields() {
      return [
        'id',
        { key: 'name', sortable: true, label: 'title' },
        { key: 'eventCategory' },
        { key: 'eventSource' },
        { key: 'startDate', sortable: true },
        { key: 'endDate', sortable: true },
        { key: 'attendees_count', sortable: true },
        { key: 'status', sortable: true, formatter: v => (v ? 'Active' : 'Inactive') },
        'actions',
      ]
    },
  },
  setup() {
    const { pagination } = paginationData()
    const { listCommunityEvents } = communityEventApi()
    return { listCommunityEvents, pagination }
  },
  methods: {
    getEvents(ctx) {
      return this.listCommunityEvents({
        page: this.pagination.currentPage,
        date_from: this.searchQuery.range[0],
        date_to: this.searchQuery.range[1],
        orderParameter: ctx.sortBy,
        orderDir: ctx.sortDesc ? 'DESC' : 'ASC',
        entityId: this.$store.getters['mainEntity/getEntityId'],
        ...this.searchQuery,
      }).then(res => {
        this.pagination.totalRows = res.data.pagination.total
        return res.data.data
      })
    },
    refreshTable() {
      this.$refs.table.refresh()
    },
    convertCommunityToQuickEvent(item) {
      const publishDateInHours = this.$moment(item.publish_date).diff(this.$moment(), 'hours')
      return JSON.stringify({
        ...item,
        eventDates: { startDate: this.$moment().isAfter(item.startDate) ? this.$moment().format('YYYY-MM-DD HH:mm') : item.startDate, endDate: this.$moment().isAfter(item.endDate) ? this.$moment().add({ hours: 1 }).format('YYYY-MM-DD HH:mm') : item.endDate },
        types: item.eventCategory,
        url: item.onlineEventLink,
        expectedAttendeesNumber: item.attendeesCapacity,
        generalNotes: item.general_notes,
        activateBeforeHours: publishDateInHours < 0 ? 65535 : publishDateInHours,
        isAutoActivate: true,
        location: {
          city: item.city,
          country: item.country,
          state: item.state,
          lng: item.lng,
          lat: item.lat,
          street: item.street,
          postal_code: item.postal_code,
        },
      })
    },
  },
}
</script>
<style lang="">

</style>
